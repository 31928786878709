class Navigation {
  constructor(items) {
    this.items = items;
    this.navItems = document.querySelectorAll(".nav-item");
    this.titleElement = document.querySelector("title");
    this.currentSection = document.querySelector(".about");
    this.isTransitioning = false;
  }

  init() {
    this.navItems.forEach((navItem) => {
      navItem.addEventListener("click", () => {
        const itemName = navItem.dataset.item;
        if (
          this.findSelectedNavItem().name === itemName ||
          this.isTransitioning
        ) {
          return;
        }

        this.isTransitioning = true;

        const lastSelectedSection = this.currentSection;
        const currentSection = document.querySelector(`.${itemName}`);

        const transition = new TimelineMax({
          paused: true,
        })
          .fromTo(
            lastSelectedSection,
            1.0,
            {
              opacity: 1,
            },
            {
              opacity: 0,
              ease: Power4.easeInOut,
            }
          )
          .fromTo(
            currentSection,
            1.0,
            {
              opacity: 0,
            },
            {
              opacity: 1,
              ease: Power4.easeInOut,
            }
          );

        transition.play();
        setTimeout(() => {
          lastSelectedSection.style.display = "none";
          currentSection.style.display = "flex";
          this.isTransitioning = false;
          this.currentSection = currentSection;
        }, 1000);

        this.items = this.items.map((nav) => ({
          ...nav,
          selected: nav.name === itemName,
        }));

        this.titleElement.innerText = this.findSelectedNavItem().title;
      });
    });
  }

  findSelectedNavItem() {
    return this.items.find((nav) => nav.selected);
  }

  registerNavigationItemClickHandler(handler) {
    this.navItems.forEach((navItem) => {
      navItem.addEventListener("click", () => {
        const itemName = navItem.dataset.item;
        if (
          this.findSelectedNavItem().name === itemName ||
          this.isTransitioning
        ) {
          return;
        }

        this.isTransitioning = true;

        handler(itemName);

        const lastSelectedSection = document.querySelector(
          `.${this.findSelectedNavItem().name}`
        );
        const currentSection = document.querySelector(`.${itemName}`);
        const transition = new TimelineMax({
          paused: true,
        })
          .fromTo(
            lastSelectedSection,
            1.0,
            {
              opacity: 1,
            },
            {
              opacity: 0,
              ease: Power4.easeInOut,
            }
          )
          .fromTo(
            currentSection,
            1.0,
            {
              opacity: 0,
            },
            {
              opacity: 1,
              ease: Power4.easeInOut,
            }
          );

        transition.play();
        setTimeout(() => {
          lastSelectedSection.style.display = "none";
          currentSection.style.display = "flex";
          this.isTransitioning = false;
        }, 1000);
      });
    });
  }

  selectNavItem(itemName) {
    this.items = this.items.map((nav) => ({
      ...nav,
      selected: nav.name === itemName,
    }));
    this.titleElement.innerText = this.findSelectedNavItem().title;
  }

  findNavItem(itemName) {
    return this.items.find((nav) => nav.name === itemName);
  }
}

class ContactForm {
  constructor(form) {
    this.form = form;
    this.usernameField = form.querySelector("#username");
    this.emailField = form.querySelector("#email");
    this.messageField = form.querySelector("#message");
    this.submitButton = form.querySelector('button[type="submit"]');
    this.captchaToken = null;
    this.validationRules = [
      {
        field: "emailField",
        regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/,
      },
      {
        field: "messageField",
        regex: /^.+$/,
      },
      {
        field: "usernameField",
        regex: /^.+$/,
      },
    ];

    this.init();
  }

  init() {
    this.form.addEventListener("submit", (e) => {
      e.preventDefault();
      this.submit();
    });
  }

  validateFields() {
    let isValid = true;
    let errorMessage = "";

    for (const rule of this.validationRules) {
      const field = this[rule.field];
      if (!rule.regex.test(field.value)) {
        errorMessage += `Invalid ${field.name}\n`;
        isValid = false;
      }
    }

    if (!isValid) {
      alert(errorMessage);
    }

    return isValid;
  }

  async submit() {
    if (!this.validateFields()) {
      return;
    }

    try {
      this.submitButton.disabled = true;
      this.captchaToken = await this.getToken();
      await this.submitForm();
      alert("Successfully sent message!");
      this.form.reset();
    } catch (error) {
      alert(`${error} - email me - michel at calheiros dot dev`);
    } finally {
      this.submitButton.disabled = false;
    }
  }

  async getToken() {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha
          .execute("6LeUG9obAAAAAClBT5VQP09FkByOO_DepR4aUAgu", {
            action: "submit",
          })
          .then(resolve)
          .catch(reject);
      });
    });
  }

  async submitForm() {
    const response = await fetch("/message", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: this.usernameField.value,
        email: this.emailField.value,
        message: this.messageField.value,
        captcha_token: this.captchaToken,
      }),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
  }

  registerSubmitHandler(callback) {
    this.submitCallback = callback;
  }

  replaceEmail() {
    const emailField = document.getElementById("my-email");
    const text = emailField.innerText;
    const desiredText = text.replace("{{my-email}}", "michel@calheiros.dev");

    emailField.innerText = desiredText;
  }
}

window.addEventListener("DOMContentLoaded", () => {
  const navigation = new Navigation([
    {
      name: "about",
      selected: true,
      title: "calheiros.dev",
    },
    {
      name: "experience",
      selected: false,
      title: "calheiros.dev - experience",
    },
    {
      name: "toolbelt",
      selected: false,
      title: "calheiros.dev - toolbelt",
    },
    {
      name: "contact",
      selected: false,
      title: "calheiros.dev - contact",
    },
  ]);

  navigation.init();

  navigation.registerNavigationItemClickHandler((itemName) => {
    if (navigation.findSelectedNavItem().name === itemName) {
      return;
    }

    navigation.selectNavItem(itemName);

    const lastSelectedSection = document.querySelector(
      `.${navigation.findSelectedNavItem().name}`
    );
    const currentSection = document.querySelector(`.${itemName}`);
    const transition = new TimelineMax({
      paused: true,
    })
      .fromTo(
        lastSelectedSection,
        1.0,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          ease: Power4.easeInOut,
        }
      )
      .fromTo(
        currentSection,
        1.0,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: Power4.easeInOut,
        }
      );

    transition.play();
    setTimeout(() => {
      lastSelectedSection.style.display = "none";
      currentSection.style.display = "flex";
    }, 1000);
  });

  const contactForm = new ContactForm(document.querySelector(".contact-form"));
  contactForm.init();
  contactForm.registerSubmitHandler((formData) => {
    navigation.selectNavItem("about");
    fetch("/message", {
      method: "post",
      body: JSON.stringify({
        ...formData,
        captcha_token: grecaptcha.getResponse(),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        contactForm.form.reset();
      })
      .then(() => alert("Successfully sent message!"))
      .catch((error) =>
        alert(`${error} - email me - michel at calheiros dot dev`)
      );
  });

  navigation.selectNavItem("about");
  contactForm.replaceEmail();
});
